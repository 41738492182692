@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

#ekg-img{
  stroke-dasharray: 1345;
  stroke-dashoffset: 1345;
  animation: ekg-animation 3s ease-in-out forwards; 
}

@keyframes ekg-animation {
  to {
    stroke-dashoffset: 0;
  }
}